@font-face {
  font-family: 'airbnb';
  src: local('airbnb'), url(./fonts/airbnb.ttf) format('truetype');
}

  

body {
  margin: 0;
  font-family: airbnb;
  background-color: #EEEEEE, 100%;
}

code {
  font-family: 'airbnb'
}

.owl-dots .owl-dot:nth-child(n+5) {
    display: none !important;
  }


